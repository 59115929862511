import { IUser, ISearchRequest, ISearch } from '@/modules/system/types'

export interface IFeeGrid {
    id: number | null,
    name: string,
    onboarding: number,
    salary: number
}

export interface IPlace {
    id: number | null;
    name: string;
    color: string;
    order: number;
    doctors: Array<IUser>;
    docIndex: Record<number, IUser>;
}

export interface IWorkingDays {
    clinic: number,
    year: number,
    month: number,
    days: number
}

export interface IWorkingYear {
    year: number,
    months: Array<IWorkingDays>
}

export interface IManipulationType {
    id: number | null;
    code: string;
    name: string;
    group: number | null;
    groupName: string | null;
    price: number;
    lab: boolean;
    nvd: string | null;
    state: string | null;
    priceNVD: number;
    surfaces: number;
    linked: boolean;
    active: boolean;
    duration: number | null;
    processing: boolean;
    processed: boolean;
    loss: number;
}

export interface IManipulationFilter extends ISearchRequest {
    search: string;
}

export interface IManipulationSearch extends ISearch<IManipulationFilter, IManipulationType> {
}

export enum DamageMode {
    None = 'N',
    ToothOnly = 'D',
    ToothFull = 'F'
}

export interface IDamage {
    id: number | null,
    code: string,
    name: string,
    group: number | null,
    groupName: string | null,
    mode: DamageMode,
    surface: boolean,
    gum: boolean,
    curing: string | null,
    manipulations: IManipulationType[];
    processing: boolean;
}

export interface IDamageFilter extends ISearchRequest {
    search: string;
}

export interface IDamageSearch extends ISearch<IDamageFilter, IDamage> {
}

export interface ILaboratory {
    id: number | null,
    code: string,
    name: string,
    address: string | null,
    phone: string | null,
    email: string | null,
    processing: boolean
}

export interface ILabsFilter extends ISearchRequest {
    search: string;
}

export interface ILabsSearch extends ISearch<ILabsFilter, ILaboratory> {
}

export interface IManipulation {
    id: number | null;
    typeId: number | null;
    code: string;
    codeNVD?: string;
    name: string | null;
    state: string;
    visit: number | null;
    curing: number;
    tooth: number;
    price: number | null;
    done: Date | null;
    doctor: number | null;
    surfaces: string | null;
    surfacesCount: number | null;
    processed: boolean;
    editable: boolean;
    processing: boolean;
}

export interface IResearch {
    id: number | null;
    laboratory: number | null;
    patient: number | null;
    sended: Date | null;
    dueDate: Date | null;
    received: Date | null;
    details: string;
    price: number;
    state: string;
    color: string;
    doctor: number | null;
    patientName: string;
    patientPhone: string;
    patientPhoto: string | null;
    labName: string;
}

export interface IResearchFilter extends ISearchRequest {
    inactive: boolean,
    to: Date | null,
    from: Date | null
}

export interface IResearches extends ISearch<IResearchFilter, IResearch> {
}

export interface ISalarySettings {
    userId: number | null;
    userName: string | null;
    year: number | null;
    month: number | null;
    feeId: number | null;
    monthRate: number | null;
    hourRate: number | null;
    workedDays: number | null;
    workedHours: number | null;
    afterFee: string;
    append: boolean;
    processing: boolean;
}

export interface ISalaryFilter extends ISearchRequest {
    userId: number | null,
    year: number | null,
    month: number | null
}

export interface ISalarySearch extends ISearch<ISalaryFilter, ISalarySettings> {
}

export interface IFeeHistory {
    id: number | null,
    date: Date,
    type: string,
    feeId: number | null,
    name: string | null,
    patient: number | null,
    patientName: string | null,
    user: number | null,
    userName: string | null,
    manipulationId: number | null,
    manipulationCode: string | null,
    manipulationType: string | null,
    price: number | null,
    rate: number | null,
    value: number | null,
    own: string | null,
    descr: string | null,
    processing: boolean
}

export interface IFeeHistoryFilter extends ISearchRequest {
    userId: number | null,
    to: Date | null,
    from: Date | null
}

export interface IFeeHistorySearch extends ISearch<IFeeHistoryFilter, IFeeHistory> {
}

export interface IExternalMessageTemplate {
    code: string,
    title: Record<string, string>,
    text: Record<string, string>,
    processing: boolean
}

export interface IExternalMessage {
    messageId: string | null,
    messageState: string,
    messageCreated: Date,
    messageIniciator: number | null,
    userName: string | null,
    messagePlanned: string | null,
    messageVisit: number | null,
    messagePatient: number,
    patientFirstName: string | null,
    patientLastName: string | null,
    messageTemplate: string | null,
    messageTemplateName: string | null,
    messageSent: Date | null,
    messagePhone: string | null,
    messageText: string | null,
    messageError: string | null,
    companyPhone: string | null,
    visitTimeFrom: string | null,
    doctorName: string | null,
}

export interface IMessagesFilter extends ISearchRequest {
    filter: string | null,
    state: string | null,
    patientId: number | null,
    to: Date | null,
    from: Date | null
}

export interface IMessagesSearch extends ISearch<IMessagesFilter, IExternalMessage> {
}
